var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("back-button", { attrs: { to: "/admin/" } }, [
        _vm._v("Back to Settings"),
      ]),
      _c(
        "b-row",
        { attrs: { "align-h": "end" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-button-group",
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.accordion-3",
                          modifiers: { "accordion-3": true },
                        },
                      ],
                      staticClass: "avo-basic-btn mr-2",
                      attrs: { variant: "outline-primary" },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { variant: "info", icon: "list-ul" },
                      }),
                      _vm._v(" User list "),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.accordion-2",
                          modifiers: { "accordion-2": true },
                        },
                      ],
                      staticClass: "avo-basic-btn mr-2",
                      attrs: { variant: "outline-primary" },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { variant: "info", icon: "person-plus-fill" },
                      }),
                      _vm._v(" Add a user "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "accordion", attrs: { role: "tablist" } },
        [
          _c(
            "b-container",
            { staticStyle: { "margin-left": "0px" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { lg: "12", xl: "9" } },
                    [
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-2",
                            accordion: "myaccordion",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "300",
                                "font-size": "36px",
                              },
                            },
                            [_vm._v("Add a user")]
                          ),
                          _c(
                            "div",
                            { staticClass: "pt-3" },
                            [
                              _c("invite-user", {
                                on: { userInvited: _vm.userListChanged },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-collapse",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            id: "accordion-3",
                            visible: "",
                            accordion: "myaccordion",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("users-list", {
                                attrs: { refreshUserList: _vm.refreshUserList },
                                on: {
                                  userListRefreshed: _vm.userListRefreshed,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }