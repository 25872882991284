var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "users-list" } },
    [
      _c("avo-alert", {
        attrs: { avoAlerts: _vm.avoAlerts },
        on: {
          "update:avoAlerts": function ($event) {
            _vm.avoAlerts = $event
          },
          "update:avo-alerts": function ($event) {
            _vm.avoAlerts = $event
          },
        },
      }),
      _c(
        "b-overlay",
        {
          attrs: {
            show: !_vm.loaded,
            variant: "white",
            blue: "2px",
            opacity: "0.85",
            rounded: "sm",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex", staticStyle: { "margin-left": "20px" } },
            [
              _c(
                "div",
                {
                  staticClass: "user-header-text",
                  staticStyle: { "margin-right": "30px" },
                },
                [_vm._v(" List of users ")]
              ),
              _c(
                "div",
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group" },
                    [
                      _c("b-input-group-prepend", [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                      ]),
                      _c("b-form-input", {
                        staticClass: "user-search-input pl-1",
                        attrs: {
                          type: "search",
                          placeholder: "Try typing a user's name",
                        },
                        on: {
                          keyup: _vm.debounceUsers,
                          search: _vm.debounceUsers,
                        },
                        model: {
                          value: _vm.search_params.search,
                          callback: function ($$v) {
                            _vm.$set(_vm.search_params, "search", $$v)
                          },
                          expression: "search_params.search",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("span", { staticClass: "divider" }),
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "dropdown filter-dropdown",
                              attrs: { boundary: "window" },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("b-icon-funnel", {
                                        staticClass: "mr-2",
                                      }),
                                      _vm._v(
                                        _vm._s(_vm.filterInputLabel) + " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                {
                                  staticClass:
                                    "d-flex flex-column align-items-start",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "20px",
                                        "font-weight": "300",
                                      },
                                    },
                                    [_vm._v("Filters")]
                                  ),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Assigned Practices"),
                                  ]),
                                  _c("multiselect", {
                                    attrs: {
                                      "hide-selected": true,
                                      options: _vm.practices,
                                      "track-by": "practice_id",
                                      label: "name",
                                      multiple: true,
                                      "show-labels": false,
                                      "close-on-select": false,
                                      placeholder: "-- Select Practices --",
                                    },
                                    model: {
                                      value: _vm.filters.practices,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "practices", $$v)
                                      },
                                      expression: "filters.practices",
                                    },
                                  }),
                                  _c("div", { staticClass: "selecter-label" }, [
                                    _vm._v("Assigned Payers"),
                                  ]),
                                  _c("multiselect", {
                                    attrs: {
                                      "hide-selected": true,
                                      options: _vm.payers,
                                      "track-by": "payer_id",
                                      label: "name",
                                      multiple: true,
                                      "show-labels": false,
                                      "close-on-select": false,
                                      placeholder: "-- Select Payers --",
                                    },
                                    model: {
                                      value: _vm.filters.payers,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "payers", $$v)
                                      },
                                      expression: "filters.payers",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-start mt-4",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-primary-btn mr-5",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.doSearch },
                                        },
                                        [_vm._v("Apply")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "avo-basic-btn",
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.clearFilters },
                                        },
                                        [_vm._v("Clear Filters")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.users.length > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.users, function (user, index) {
                    return _c(
                      "b-row",
                      {
                        key: index,
                        class: user.active ? "user-box" : "user-box-disabled",
                        attrs: { "align-v": "center" },
                      },
                      [
                        _c("b-col", { attrs: { cols: "4" } }, [
                          _c(
                            "div",
                            {
                              style:
                                "disabled:" +
                                user.active +
                                "; padding-left:10px; border-left:3px solid " +
                                _vm.colors[index % 7],
                            },
                            [
                              _c("div", { staticClass: "avo-text-light" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      user.active ? "Name" : "Deactivated User"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                { class: user.active ? "user-name-text" : "" },
                                [_vm._v(" " + _vm._s(user.name) + " ")]
                              ),
                            ]
                          ),
                        ]),
                        _c("b-col", { attrs: { cols: "4" } }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-right": "20px" } },
                            [
                              _c("div", { staticClass: "avo-text-light" }, [
                                _vm._v(" Email "),
                              ]),
                              _c("div", { staticClass: "avo-text-bold" }, [
                                _vm._v(" " + _vm._s(user.email) + " "),
                              ]),
                            ]
                          ),
                        ]),
                        _c("b-col", { attrs: { cols: "3" } }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-right": "20px" } },
                            [
                              _c("div", { staticClass: "avo-text-light" }, [
                                _vm._v(" Role(s) "),
                              ]),
                              _c("div", { staticClass: "avo-text-bold" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      String(user.roles).replace(/,/g, ", ")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("b-col", { attrs: { cols: "1" } }, [
                          _c(
                            "div",
                            [
                              _c(
                                "b-dropdown",
                                {
                                  staticClass: "avo-action-dropdown",
                                  staticStyle: { "min-width": "none" },
                                  attrs: { "no-caret": "", right: true },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("b-icon", {
                                              attrs: { icon: "three-dots" },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewUserProfileModal(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          variant: "secondary",
                                          icon: "person",
                                        },
                                      }),
                                      _vm._v(" Edit user profile"),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewUserDeactivateModal(
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          variant: "secondary",
                                          icon: user.active
                                            ? "person-x-fill"
                                            : "person-plus-fill",
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            user.active
                                              ? "Deactivate user"
                                              : "Reactivate user"
                                          )
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewPasswordResetModal(
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          variant: "secondary",
                                          icon: "key-fill",
                                        },
                                      }),
                                      _vm._v(" Reset password"),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewUserRolesModal(
                                            index,
                                            user
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("b-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          variant: "secondary",
                                          icon: "briefcase",
                                        },
                                      }),
                                      _vm._v(" Update roles"),
                                    ],
                                    1
                                  ),
                                  _vm.userNeedsPracticeAssociated(user)
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewUserPracticeModal(
                                                index,
                                                user
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              variant: "secondary",
                                              icon: "building",
                                            },
                                          }),
                                          _vm._v(" Update practice assignment"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.userNeedsProviderAssociated(user)
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewUserProviderModal(
                                                index,
                                                user
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              variant: "secondary",
                                              icon: "person-badge",
                                            },
                                          }),
                                          _vm._v(" Update provider for user"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.userNeedsWorkerAssignments(user)
                                    ? _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewUserAssignmentsModal(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("b-icon", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              variant: "secondary",
                                              icon: "card-checklist",
                                            },
                                          }),
                                          _vm._v(" Update assignments"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-modal",
                                {
                                  staticClass: "modal",
                                  attrs: {
                                    id: "user-profile-modal-" + index,
                                    "hide-footer": "",
                                    "hide-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "avo-header-text mt-2 mb-4 bold",
                                      staticStyle: { "text-align": "center" },
                                    },
                                    [_vm._v(" Update user profile ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "avo-text-light mb-4" },
                                    [
                                      _c(
                                        "b-form-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "user-profile-given-name-label",
                                                    label: "First name",
                                                    "label-for":
                                                      "user-profile-given-name",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "user-profile-given-name",
                                                      type: "text",
                                                      placeholder:
                                                        user.given_name,
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editProfileForm
                                                          .givenName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editProfileForm,
                                                          "givenName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editProfileForm.givenName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "user-profile-family-name-label",
                                                    label: "Last name",
                                                    "label-for":
                                                      "user-profile-family-name",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "user-profile-family-name",
                                                      type: "text",
                                                      placeholder:
                                                        user.family_name,
                                                      required: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editProfileForm
                                                          .familyName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editProfileForm,
                                                          "familyName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editProfileForm.familyName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "user-profile-nick-name-label",
                                                    label: "Nickname",
                                                    "label-for":
                                                      "user-profile-nick-name",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "user-profile-nick-name",
                                                      type: "text",
                                                      placeholder:
                                                        user.nick_name,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editProfileForm
                                                          .nickName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editProfileForm,
                                                          "nickName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editProfileForm.nickName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-basic-btn ml-2",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.hideUserProfileModal(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "avo-primary-btn ml-2",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateUserProfile(
                                                    index,
                                                    _vm.users[index].worker_id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Update user profile")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-modal",
                                {
                                  staticClass: "modal",
                                  attrs: {
                                    id: "reset-password-modal-" + index,
                                    "hide-footer": "",
                                    "hide-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "avo-header-text mt-2 mb-4 bold",
                                      staticStyle: { "text-align": "center" },
                                    },
                                    [
                                      _vm._v(
                                        " Send password reset email to " +
                                          _vm._s(_vm.users[index].name) +
                                          "? "
                                      ),
                                      _c("br"),
                                      _c("b-icon", {
                                        staticClass: "mt-2 mb-2",
                                        attrs: {
                                          variant: "secondary",
                                          "font-scale": "5",
                                          icon: "key",
                                        },
                                      }),
                                      _c("b-icon", {
                                        staticClass: "ml-2 mt-2 mb-2",
                                        attrs: {
                                          variant: "secondary",
                                          "font-scale": "5",
                                          icon: "mailbox",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "avo-text-light mb-4" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.users[index].name) +
                                          " will receive an email with a link to reset their password. "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-basic-btn ml-2",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.hidePasswordResetModal(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "avo-primary-btn ml-2",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.sendPasswordReset(
                                                    index,
                                                    _vm.users[index].user_id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Yes, reset password")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-modal",
                                {
                                  staticClass: "modal",
                                  attrs: {
                                    id: "user-practice-modal-" + index,
                                    "hide-footer": "",
                                    "hide-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "avo-header-text mt-2 mb-4 bold",
                                      staticStyle: { "text-align": "center" },
                                    },
                                    [
                                      _vm._v(
                                        " Select a new practice to associate to " +
                                          _vm._s(_vm.users[index].name) +
                                          ": "
                                      ),
                                      _c("br"),
                                      _c("b-icon", {
                                        staticClass: "mt-2 mb-2",
                                        attrs: {
                                          variant: "secondary",
                                          "font-scale": "5",
                                          icon: "building",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "avo-text-light mb-4" },
                                    [
                                      _c("practice-multiselect", {
                                        model: {
                                          value: _vm.selectedPractice,
                                          callback: function ($$v) {
                                            _vm.selectedPractice = $$v
                                          },
                                          expression: "selectedPractice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-basic-btn ml-2",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.hideUserPracticeModal(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "avo-primary-btn ml-2",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateUserPractice(
                                                    index,
                                                    _vm.users[index].user_id,
                                                    _vm.selectedPractice
                                                      .practice_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Update practice assignment"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-modal",
                                {
                                  staticClass: "modal",
                                  attrs: {
                                    id: "user-provider-modal-" + index,
                                    "hide-footer": "",
                                    "hide-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "avo-header-text mt-2 mb-4 bold",
                                      staticStyle: { "text-align": "center" },
                                    },
                                    [
                                      _vm._v(
                                        " Select a new provider to associate to " +
                                          _vm._s(_vm.users[index].name) +
                                          ": "
                                      ),
                                      _c("br"),
                                      _c("b-icon", {
                                        staticClass: "mt-2 mb-2",
                                        attrs: {
                                          variant: "secondary",
                                          "font-scale": "5",
                                          icon: "person-badge",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "avo-text-light mb-4" },
                                    [
                                      _c("provider-multiselect", {
                                        attrs: {
                                          multiple: false,
                                          "close-on-select": true,
                                        },
                                        model: {
                                          value: _vm.selectedProvider,
                                          callback: function ($$v) {
                                            _vm.selectedProvider = $$v
                                          },
                                          expression: "selectedProvider",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-basic-btn ml-2",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.hideUserProviderModal(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "avo-primary-btn ml-2",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateUserProviders(
                                                    index,
                                                    _vm.users[index].user_id,
                                                    _vm.selectedProvider
                                                      .provider_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Update provider assignment"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-modal",
                                {
                                  staticClass: "modal",
                                  attrs: {
                                    id: "user-deactivate-modal-" + index,
                                    "hide-footer": "",
                                    "hide-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "avo-header-text mt-2 mb-4 bold",
                                      staticStyle: { "text-align": "center" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            user.active
                                              ? "Deactivate"
                                              : "Reactivate"
                                          ) +
                                          " account for " +
                                          _vm._s(_vm.users[index].name) +
                                          "? "
                                      ),
                                      _c("br"),
                                      _c("b-icon", {
                                        staticClass: "mt-2 mb-2",
                                        attrs: {
                                          variant: "secondary",
                                          "font-scale": "5",
                                          icon: user.active
                                            ? "person-x-fill"
                                            : "person-plus-fill",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "avo-text-light mb-4" },
                                    [
                                      _vm._v(
                                        " Deactivated users are unable to log in. "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-basic-btn ml-2",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.hideUserDeactivateModal(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "avo-primary-btn ml-2",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deactivateUser(
                                                    index,
                                                    _vm.users[index].user_id,
                                                    user.active
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  user.active
                                                    ? "Yes, deactivate user"
                                                    : "Yes, reactivate user"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "b-modal",
                                {
                                  staticClass: "modal",
                                  attrs: {
                                    id: "user-roles-modal-" + index,
                                    "hide-footer": "",
                                    "hide-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "avo-header-text mb-4 bold",
                                      staticStyle: { "text-align": "center" },
                                    },
                                    [
                                      _vm._v(
                                        " Update roles for " +
                                          _vm._s(_vm.users[index].name) +
                                          ": "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-form",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          options: _vm.rolesOptions,
                                          "show-labels": false,
                                          "hide-selected": true,
                                          "close-on-select": false,
                                          "track-by": "text",
                                          label: "text",
                                          multiple: true,
                                          placeholder: "-- Select Roles --",
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.form.roles,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "roles", $$v)
                                          },
                                          expression: "form.roles",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "b-button-group",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "avo-basic-btn ml-2",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.hideUserRolesModal(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "avo-primary-btn ml-2",
                                              attrs: { variant: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateUserRoles(
                                                    index,
                                                    _vm.users[index].user_id,
                                                    _vm.form.roles
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Update roles")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("worker-assignments-update", {
                                attrs: {
                                  worker: {
                                    worker_id: _vm.users[index].worker_id,
                                    name: _vm.users[index].name,
                                  },
                                  index: index,
                                  practicesOptions: _vm.practicesOptions,
                                  payersOptions: _vm.payersOptions,
                                  payersGroupsOptions: _vm.payersGroupsOptions,
                                },
                                on: {
                                  updated: function ($event) {
                                    _vm.getUsers()
                                    _vm.showSuccessMessage(
                                      "User assignments updated"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.totalRows,
                              "per-page": _vm.perPage,
                              align: "center",
                            },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm.loaded
            ? _c(
                "div",
                {
                  staticClass: "user-header-text",
                  staticStyle: { "text-align": "center" },
                },
                [_vm._v(" No users found ")]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }